import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteribeiraopires.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3543303',
  schema: 'pbi_dte_ribeiraopires',
  groupId: 'e4f821fd-13ec-4f5a-a2d0-ca3c1ccca055',
  analiseGeral: '5eadacbb-fa4f-4ed8-990e-5d52b67b434e',
  analiseUsuarios: 'd6483c64-3f30-41ff-aa7d-41dc19bbb004',
  analiseComunicados: '3606cf2d-c788-4220-a17f-32d81d38a447',
  analiseProcurador: 'ffd3454d-c49b-4ff7-935a-c231fcf1ee95',
  analiseEventos: 'd2aaedad-62e2-4eeb-a9dd-6a10ac48a5d3',
  analiseContribuintes: '3345cbd5-4751-47b0-a32b-c36c929bb309'
};
